"use client";

import { useGetAccountInfo } from "@hooks/sdkDappHooks";

import { useMemo } from "react";
import toast from "react-hot-toast";

export function OpaqueId() {
  const { address } = useGetAccountInfo();

  const encrypt = (str: string, decrypt = false) => {
    const shift = 23;
    const s = decrypt ? (26 - shift) % 26 : shift;
    const n = s > 0 ? s : 26 + (s % 26);
    return [...str]
      .map((l, i) => {
        const c = str.charCodeAt(i);
        if (c >= 65 && c <= 90) {
          return String.fromCharCode(((c - 65 + n) % 26) + 65);
        }
        if (c >= 97 && c <= 122) {
          return String.fromCharCode(((c - 97 + n) % 26) + 97);
        }
        if (c >= 48 && c <= 57) {
          const numShift = decrypt ? (10 - (shift % 10)) % 10 : shift % 10;
          return String.fromCharCode(((c - 48 + numShift) % 10) + 48);
        }
        return l;
      })
      .join("");
  };

  const coded = useMemo(() => {
    if (!address) return null;

    const trimmed = address.slice(32);

    const enc = encrypt(trimmed);
    return enc;
  }, [address]);

  const onClick = () => {
    // copy to clipboard
    if (!coded) return;
    navigator.clipboard.writeText(coded);
    toast.success("Copied to clipboard");
  };

  if (!address) return null;
  return (
    <div onClick={onClick}>
      <p className="cursor-pointer select-none text-sm font-normal text-secondary transition-colors duration-300">
        {coded}
      </p>
    </div>
  );
}
